<template>
    <div class="job-create">
        <div class="job-create-multiple" v-loading.fullscreen="loading">
            <div class="job-step">
                <StepProcess :step="step" />
            </div>
            <div class="job-info" v-if="step <= 1">
                <UploadFile @upload-form="handleUploadForm" @update-step="handleUpdateStep" />
            </div>
            <div class="job-keys" v-if="step == 2 || step == 3">
                <KeysMatch :jobItems="jobItems" @update-step="handleUpdateStep" :systemItems="systemItems" @set-match="handleSetMatch" />
            </div>
            <div class="job-view" v-if="step > 3">
                <JobEdit :jobData="jobData" :batch-no="batchNo" :customerName="customerName" @job-publish="handleJobPublish" @multiple-edit="handleMultipleEdit" />
            </div>
        </div>

        <!-- <div class="lbd-sign"></div> -->
    </div>
</template>

<script>
import Vue from 'vue';
import StepProcess from './layout/StepProcess.vue';
import UploadFile from './layout/UploadFile.vue';
import KeysMatch from './layout/KeysMatch.vue';
import JobEdit from './layout/JobEdit.vue';
import jobCreateMultiService from '#/js/service/jobCreateMultiService.js';

Vue.directive('focus', {
    inserted: function (el) {
        el.querySelector('input') && el.querySelector('input').focus();
    },
    bind: function (el) {
        el.querySelector('input') && el.querySelector('input').focus();
    }
})

export default {
    name: 'createMultiple',
    data() {
        return {
            loading: false,
            step: 0,
            batchNo: '',
            jobItems: [],
            systemItems: [],
            jobData: [],
            customerName: ''
        }
    },
    components: {
        StepProcess,
        UploadFile,
        KeysMatch,
        JobEdit
    },
    computed: {
        isAdministrator() {
            return this.$store.state.user.userInfo.isAdministrator || false;
        },
        isJobPermission() {
            return (this.$store.state.user.userInfo.privilegeCodeList || []).includes('Job');
        }
    },
    created() {
        this.getSystemItems();
    },
    mounted() {
        if(!this.isAdministrator && !this.isJobPermission){
            shortTips("权限不足，请联系管理员")
            setTimeout(() => {
                location.href = "/Headhunting/#/";
            }, 2000)
        }
    },
    methods: {
        handleUploadForm(val) {
            let _self = this;
            let formData = new FormData();
            this.customerName = val.customerName;
            Object.keys(val.formValue).forEach(key =>{
                formData.append(key, val.formValue[key]);
            });

            this.loading = true;
            jobCreateMultiService.batchImport(formData)
                .then(res => {
                    this.step = 2;
                    this.batchNo = res.batchNo;
                    this.jobItems = res.jobItems;
                    this.step = 2;
                }).finally(() =>{
                    this.loading = false;
                })
        },
        getSystemItems() {
            jobCreateMultiService.getMappingRule()
                .then(res => {
                    res.unshift({mutiMatch: true, name: '请选择', property: null, required: false});
                    res.forEach((item, index) =>{
                        item.count = 0;
                    })
                    this.systemItems = res;
                })
        },
        handleSetMatch(match) {
            this.loading = true;
            this.jobItems.forEach(item =>{
                item.hasOwnProperty('errMessage') && (delete item.errMessage);
            });

            jobCreateMultiService.getImportJobs(this.batchNo, this.jobItems)
                .then(res => {
                    res.forEach((item, index) =>{
                        item.locations = this.handleLocations(item.locations);
                        item.checked = true;
                    });
                    this.jobData = res;
                    this.step = 4;
                }).finally(() =>{
                    this.loading = false;
                })
        },
        handleUpdateStep(step) {
            this.step = step;
        },
        handleJobPublish(jobs) {
            this.loading = true;

            jobCreateMultiService.publishJobs(this.batchNo, jobs)
                .then(res => {
                    this.handleLocalStorage();
                    // location.href = '/Headhunting/Job/CreateMultiple/Result?batchNo=' + this.batchNo + '&type=jobresult';
                    // location.href = '/Headhunting/MyCompany.html#/jobCreate/multi/publishResult?batchNo=' + this.batchNo + '&type=jobresult';
                    location.href = '/#/jobCreate/multi/publishResult?batchNo=' + this.batchNo + '&type=jobresult';
                }).finally(() =>{
                    this.loading = false;
                })
        },
        handleLocations(arr) {
            return arr && arr.slice(0, 5);
        },
        handleLocalStorage() {
            localStorage.removeItem('batchNo');
        },
        handleMultipleEdit(type, json) {
            this.jobData.some(item => {
                if(item.checked) {
                    Object.keys(json).forEach(key => {
                        item[key] = json[key];
                    })
                }
            })
        }
  }
}
</script>


<style lang="scss" scoped>
.job-create {
    height: 100%;
    // overflow-y: auto;
    // margin: 20px 20px 30px;
    width: 1200px;
    margin: 20px auto;
    padding-bottom: 20px;

    .job-create-multiple {
        min-height: 95%;
        // width: 1200px;
        // margin: 54px auto 84px;
        background-color: #ffffff;
        border-radius: 8px;

        /deep/ .el-upload__input {
            display: none;
        }

        .job-view {
            padding: 0 20px 30px;
        }
    }
    .lbd-sign {
        margin: 20px 0;
        &:after {
            content: '© 2023 liebide.com';
            margin-left: 50%;
            text-align: center;
            font-size: 14px;
            color: #999;
        }
    }
}
</style>
<style lang="scss">
    .job-customers-tree {
        height: 260px;
        overflow-y: scroll;

        .el-tree-node__expand-icon {
            display: none;
        }
    }

    // body {
    //     background: #fff !important;
    // }
    .job-multiple {
        width: 1190px;
        margin: 0 auto;
        min-height: 815px;
    }
    .el-popover {
        height:500px;
        // overflow-y: auto;

        .item-system-select {

            .select-item {
                height: 40px;
                line-height: 40px;
                padding-left: 10px;
                cursor: pointer;
                position: relative;

                &:hover {
                    background: #eaf8f4;
                    color: #38BC9C;
                }
            }

            .seletct-item-requiry {
                &::before {
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    content: '*';
                    color: red;
                }
            }
        }

        .item-des-wrap {
            padding: 20px;

            .item-des-header {
            }

            .item-des-edit {

                .des-edit-result {
                    line-height: 24px;
                    // max-height: 275px;
                    max-height: 370px;
                    overflow-y: auto;
                }

                .des-edit-box {
                    line-height: 24px;

                    .el-textarea {

                        .el-textarea__inner {
                            width: 100%;
                            line-height: 24px;
                            padding: 10px 20px;
                            background: #F8F8F8;
                            outline: none;
                            border: none;
                            max-height: 277px;
                            overflow-y: auto;
                        }
                    }
                }
            }
        }

        .item-salary-edit {
            padding: 5px;
            position: relative;

            .salary-edit-input {
                position: relative;

                .el-input {
                    display: inline-block;
                    width: 99px;

                    .el-input__inner {
                        width: 100px;
                        padding-right: 25px;
                    }
                }

                .salary-space {
                    color: #eee;
                    display: inline-block;
                    margin: 0 20px;
                }

                .salary-unit {
                    width: 14px;
                    height: 14px;
                    right: 8px;
                    z-index: 10;
                    line-height: 14px;
                    color: #999;
                    top: 0;
                }

                .salary-unit-min {
                    left: -90px;
                }

                .salary-unit-max {
                    left: 252px;
                }
            }

            .valid-salary-err {

                .el-input__inner {
                    border-color: #ff6600;
                    color: #ff6600;
                }
            }

            .error-tip {
                font-size: 0;
                margin-top: 10px;

                &-item {
                    font-size: 12px;
                    display: inline-block;
                    width: 45%;
                    text-align: center;
                    color: #ff6600;
                    height: 10px;
                    line-height: 10px;

                    &:nth-child(1) {
                        margin-right: 10%;
                    }
                }
            }
        }

        .item-analyse-wrap {
            padding: 20px;

            .item-analysis-edit {

                .item-analyse-result {
                    line-height: 24px;
                    max-height: 275px;
                    overflow-y: auto;

                    .analysis-item {
                        color: #2a2a2a;
                        font-size: 14px;
                        margin-bottom: 5px;

                        span {
                            font-size: 16px;
                            color: #444;
                        }
                    }
                }

                .analyse-edit-box {

                    .edit-box-item {
                        display: inline-block;
                        margin-bottom: 20px;
                        position: relative;

                        .sub-title {
                            position: absolute;
                            width: 80px;
                            top: 0;
                            left: 0;
                            color: #444;
                            font-size: 16px;
                            text-align: right;
                        }

                        .el-textarea {
                            width: 400px;
                            margin-left: 80px;

                            .el-textarea__inner {
                                width: 400px;
                                background: #f8f8f8;
                                outline: none;
                                border: none;
                            }
                        }
                    }

                    .edit-expect-rank {

                        .el-textarea {

                            .el-textarea__inner {
                                max-height: 35px;
                                overflow-y: auto;
                            }
                        }
                    }

                    .edit-expect-firm {

                        .el-textarea {

                            .el-textarea__inner {
                                max-height: 35px;
                                overflow-y: auto;
                            }
                        }
                    }

                    .edit-expect-more {

                        .add-info-text {

                            .info-content {
                                margin-left: 90px;
                                padding: 5px 0;
                                font-size: 14px;
                                color: #666;
                            }
                        }

                        .el-textarea {

                            .el-textarea__inner {
                                max-height: 130px;
                                overflow-y: auto;
                            }
                        }
                    }
                }
            }
        }

        .item-header-common {
            margin-bottom: 30px;
            color: #666;
            text-align: left;

            span {
                display: inline-block;
                height: 32px;
                line-height: 32px;
                text-align: center;

                &:nth-child(1) {
                    font-size: 18px;
                    color: #444;
                }

                &:nth-child(2) {
                    margin-left: 330px;
                    width: 77px;
                    border-radius: 16px;
                    cursor: pointer;
                    text-indent: 22px;
                    position: relative;
                    color: #999;

                    &::before {
                        content: ' ';
                        position: absolute;
                        margin: auto;
                        width: 18px;
                        height: 18px;
                        top: 0;
                        bottom: 0;
                        left: 15px;
                        background: url('~@src/assets/images/jobCreateMulti/icon-edit-default.png') no-repeat;
                    }

                    &:hover {
                        color: #38BC9C;
                        background: #ecf9f6;

                        &::before {
                            background: url('~@src/assets/images/jobCreateMulti/icon-edit-active.png') no-repeat;
                        }
                    }
                }

                &:nth-child(3) {
                    margin-left: 30px;
                    width: 77px;
                    border-radius: 16px;
                    cursor: pointer;
                    background: #eee;
                    position: relative;
                    text-indent: 22px;
                    color: #999;

                    &::before {
                        content: ' ';
                        position: absolute;
                        margin: auto;
                        width: 18px;
                        height: 18px;
                        top: 0;
                        bottom: 0;
                        left: 15px;
                        background: url('~@src/assets/images/jobCreateMulti/icon-save-default.png') no-repeat;
                    }

                    &:hover {
                        color: #38BC9C;
                        background: #ecf9f6;

                        &::before {
                            background: url('~@src/assets/images/jobCreateMulti/icon-save-active.png') no-repeat;
                        }
                    }
                }
            }
        }

        .item-analyse-header {
            span:nth-child(2) {
                margin-left: 170px;
            }
        }

        .item-location-edit {
            padding: 20px;
            overflow-x: hidden;
        }

        .item-commission-edit {
            padding: 20px;

            .item-header-commission {
                margin-bottom: 20px;

                span:nth-child(2) {
                    margin-left: 90px;

                    &:hover {
                        color: #38bc9c;
                        background: #ecf9f6;
                    }
                }
            }

            .item-content-tab {
                color: #3f3f3f;

                span {
                    color: #666;
                    font-size: 14px;
                    padding-right: 5px;
                    cursor: pointer;

                    &:nth-child(2) {
                        padding-left: 5px;
                    }
                }

                .tab-active {
                    color: #38bc9c;
                }
            }

            .item-content-input {
                position: relative;

                .el-input {

                    .el-input__inner {
                        width: 232px;
                        height: 44px;
                        line-height: 44px;
                        background: #f8f8f8;
                        border: none;
                        margin-top: 10px;
                    }
                }

                .commission-type-text {
                    position: absolute;
                    width: 16px;
                    height: 16px;
                    line-height: 16px;
                    right: 20px;
                    top: 22px;
                    bottom: 0;
                }
            }
        }

        .el-input__inner:focus {
            border-color: #38bc9c;
        }
    }

    .match-popover {
        height: auto;
    }

    .commission-popover {
        height: auto;
    }

    .salary-popover {
        height: auto;
    }

    .el-dropdown-menu {

        .el-dropdown-menu__item:not(.is-disabled) {
            &:hover {
                background-color: #eaf8f4;
            }
        }
    }

    .el-popover[x-placement^=bottom] {
        margin-top: 1px !important;
    }

    .el-popover[x-placement^=top] {
        margin-bottom: 1px !important;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    input[type="number"]{
        -moz-appearance: textfield;
    }

    ::-ms-clear{display: none;}
    ::-ms-reveal{display: none;}

</style>